
































import {Component, Vue} from 'vue-property-decorator';
import ViewDesign from "@/components/global/ViewDesign.vue";

@Component({  components: {ViewDesign}})
export default class ConfirmEmail extends Vue {

    private hash: string = "";
    private loading: boolean = false;
    private success: boolean = true;

    async mounted() {
        this.loading = true;
        const query = { ...this.$route.query };
        if (query.h) {
            const h = query.h;
            this.hash = h.toString();
            delete query.h;
            await this.$router.push(query);
        }
        if(!this.hash) {
            await this.$router.push('login');
        }

        this.$webApi.confirmEmail(this.hash).then((result) => {
            if (result) {
                //success
            } else {
                //failed
            }
        }).finally(() => {
            this.loading = false;
        })
    }

}

